import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Breadcrumbs`}</h2>
    <ul>
      <li parentName="ul"><span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{` > Campaigns > Add `}</li>
    </ul>
    <h2>{`Tab: `}<em parentName="h2">{`Campaign variants`}</em></h2>
    <p>{`It is possible to deactivate or activate variants, add variants, and check the percentage of each. `}</p>
    <h2>{`Tab: `}<em parentName="h2">{`Settings`}</em></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Start date`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Datepicker to define when the A/B testing will start.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`End date`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datepicker to define when the A/B testing will end.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Automatically show winner to all visitors:`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` -  `}<em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Distribute page variations by`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - `}<em parentName="td">{`Visitor`}</em>{` or `}<em parentName="td">{`Session`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Page distribution mode`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Define how test versions are distributed to users selecting `}<em parentName="td">{`Sequencial`}</em>{`, `}<em parentName="td">{`Random`}</em>{` or `}<em parentName="td">{`Random with differents weights`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Test conversion goal`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Before the test starts, what is the meaning for the conversion should be defined: `}<em parentName="td">{`Pages`}</em>{`, `}<em parentName="td">{`Event lists`}</em>{`, `}<em parentName="td">{`Contacts`}</em>{`, `}<em parentName="td">{`Campaign contacts`}</em>{`, `}<em parentName="td">{`Campaign elements accepted`}</em>{` or `}<em parentName="td">{`Event (via workflow)`}</em></td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`If the mode “Random with different percentages” is selected,  the percentage for each variant should be defined, in the tab “Campaign Variants”. `}</p>
    </blockquote>
    <h2>{`Tab: `}<em parentName="h2">{`A/B results`}</em></h2>
    <p>{`A dashboard with the most relevant metrics for A/B testing:`}</p>
    <ul>
      <li parentName="ul">{`Number of prints per variant​`}</li>
      <li parentName="ul">{`Number of conversion per variant​: Sum of conversions associated to a visitor impacted by one of the variations.​`}</li>
      <li parentName="ul">{`Conversion rate per variant​:             Conversion/Prints*100 +- standard deviation​ Confidence = 95%​`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Percentage Improvement​`}
        <ul parentName="li">
          <li parentName="ul">{`((Variant conversion rate – control conversion rate )/control conversion rate)*100`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Chance to beat original​:`}
        <ul parentName="li">
          <li parentName="ul">{`Only available if + 25 prints​`}</li>
          <li parentName="ul">{`Calculate the standard error for the original and variation.​`}</li>
          <li parentName="ul">{`Calculate the Z-score for the original and variation.​`}</li>
          <li parentName="ul">{`Based on the Z-score use a statistics table to determine the p-value and CTBO.​`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      